import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationType } from '../../_models/_enums/integration_type';
import { NbDialogRef } from '@nebular/theme';
import { Operator } from '../../_models/operator.model';

@Component({
  selector: 'puntos-integration-selector',
  templateUrl: './integration-selector.component.html',
  styleUrls: ['./integration-selector.component.scss'],
})
export class IntegrationSelectorComponent implements OnInit {
  @Input() operator: Operator;
  @Output() onSetIntegrationType = new EventEmitter<IntegrationType>();

  status: string = 'basic';
  acceptButtonStatus = 'success';
  deleteButtonStatus = 'danger';

  constructor(private ref: NbDialogRef<IntegrationSelectorComponent>) { }

  selectedType: IntegrationType = IntegrationType.DEFAULT;
  allTypes = [
    // { key: IntegrationType.MERCADO_PAGO_RT, value: 'Mercado Pago Realtime' },
    // { key: IntegrationType.MERCADO_PAGO, value: 'Mercado Pago' },
    // { key: IntegrationType.PAYWAY, value: 'Payway' },
    { key: IntegrationType.DRAGON_FISH, value: 'Dragon Fish' },
    { key: IntegrationType.CENTUM, value: 'Centum' },
    { key: IntegrationType.FUDO, value: 'Fudo' },
  ];
  types = [];

  ngOnInit() {
    if (!this.operator || !this.operator.activeBranch || !this.operator.activeBranch.permission_group || !this.operator.activeBranch.permission_group.permissions) {
      this.types = this.allTypes;
    } else {
      const permissions = this.operator.activeBranch.permission_group.permissions.integrations;
      if (permissions.mercado_pago) {
        this.types.push(this.allTypes.find(t => t.key === IntegrationType.MERCADO_PAGO));
      }
      if (permissions.mercado_pago_webhook) {
        this.types.push(this.allTypes.find(t => t.key === IntegrationType.MERCADO_PAGO_RT));
      }
      if (permissions.payway) {
        this.types.push(this.allTypes.find(t => t.key === IntegrationType.PAYWAY));
      }
      if (permissions.dragon_fish) {
        this.types.push(this.allTypes.find(t => t.key === IntegrationType.DRAGON_FISH));
      }
      if (permissions.centum) {
        this.types.push(this.allTypes.find(t => t.key === IntegrationType.CENTUM));
      }
      this.types.push(this.allTypes.find(t => t.key === IntegrationType.FUDO));

    }
  }

  accept() {
    this.onSetIntegrationType.emit(this.selectedType);
    this.ref.close();
  }

  cancel() {
    this.ref.close();
  }

}
