<div class="next-product-cluster">
  
  <p class="points" *ngIf="missingPoints > 0">
    Te faltan
    <nb-icon class="ml-1" icon="logo" pack="puntos"></nb-icon>
    <span class="mx-1">{{ missingPoints | ngxNumberWithCommas }}</span>
    para el siguiente premio
  </p>

  <p class="points" *ngIf="missingPoints <= 0">
    ¡Felitaciones! Has alcanzado el premio:
    {{ client?.nextProductOffer?.product?.name }}
  </p>

  <nb-progress-bar
    [value]="nextProductPercentage"
    size="tiny"
    class="status-primary"
  ></nb-progress-bar>
  
  <nb-card [routerLink]="['/app/prizes', client?.nextProductOffer?.id, 'checkout']">
    <div class="product">
      <div
        class="image"
        [style.background-image]="
          'url(' + client?.nextProductOffer?.product?.imageSizes['thumb'] + ')'
        "
      ></div>
      <div class="info">
        <p class="name">{{ client?.nextProductOffer?.product?.name }}</p>
        <p class="category">
          {{ client?.nextProductOffer?.product?.categories[0].name }}
        </p>
      </div>
    </div>
    <div class="points-section">
      <nb-icon icon="logo" pack="puntos"></nb-icon>
      <span class="value">{{ client?.nextProductOffer?.value }}</span>
    </div>
  </nb-card>
</div>
