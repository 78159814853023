import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import { HistoryService } from '../../../_services/history.service';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import { ConfirmComponent } from '../../../_dialogs/confirm/confirm.component';
import { filter, switchMap, tap } from 'rxjs/operators';
import {NotificationComponent} from '../../../_dialogs/notification/notification.component';
import { HistoryTransaction } from '../../../_models/history/history_transaction';
import { Business } from '../../../_models/business.model';
import { tagTypeName } from '../../../_models/tag.model';

/* tslint:disable:component-selector */
@Component({
  selector: '[puntos-history-read]',
  templateUrl: './history-read.component.html',
  styleUrls: ['./history-read.component.scss'],
})
export class HistoryReadComponent implements OnInit {
  tagTypeName = tagTypeName;
  @Input() transaction: HistoryTransaction;
  @Input() business: Business = null;
  @Input() role = 'user';
  loadingCancellation = false;

  showname: string = '';
  transactionTooltip: string = 'Suma de puntos';

  constructor(private historyService: HistoryService,
              private dialogService: NbDialogService,
              private toastrService: NbToastrService,
              private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.transaction) {
      if (this.transaction.score === 0) {
        this.transaction.status = 'Cancelado';
      } else if (this.transaction.score > 0 && this.transaction.status === 'expired') {
        this.transaction.status = 'Sumado';
      } else if (this.transaction.score > 0 && this.transaction.status === 'active') {
        this.transaction.status = 'Sin abrir';
      } else {
        this.transaction.status = 'Sustraído';
      }

      if (this.transaction.user_name) {
        this.showname = this.transaction.user_name;
      } else if (this.transaction.user_email) {
        this.showname = this.transaction.user_email;
      } else if (this.transaction.user_dni) {
        this.showname = this.transaction.user_dni;
      }
    }

    const transactionAux = new HistoryTransaction(this.transaction);
    if (this.transaction.score === 0) {
      this.transactionTooltip = 'Suma de puntos cancelada';
    } else {
      this.transactionTooltip = `Suma de puntos (${transactionAux.getMethodName()})`;
    }

  }

  cancel() {
    this.loadingCancellation = true;
    this.dialogService
      .open(ConfirmComponent, {
        context: {
          status: 'danger',
          title: '¿Estás seguro de eliminar esta lectura?',
          message: 'Si el usuario ya canjeó estos puntos, no podremos hacerlo.',
          acceptButtonText: 'Sí, eliminar',
        },
      })
      .onClose
      .pipe(
        filter((t) => t),
        switchMap(() => this.historyService.cancelRead(this.transaction.read_id)),
        tap((t: { transaction: any }) => this.transaction ),
      )
      .subscribe(
        () => {
          this.transaction.score = 0;
          // this.transaction.created_at = moment().toISOString();
          this.toastrService.success('La lectura fué eliminada correctamente.', '¡Bien!');
        },
        (err) => this.dialogService.open(NotificationComponent, { context: { title: '¡Ups!', message: err.error.message } }),
      )
      .add(() => {
          this.loadingCancellation = false;
          this.cd.detectChanges();
      });




  }

}
